import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => (
  <header>
    <div className="f_h1">
      <div className="hlog">
      <Link
        to="/"
      >
      <StaticImage
        src="../images/zemepisec_logo.png"
        alt="Logo projektu Zeměpisec.cz"
        placeholder="none"
        layout="fixed"
        width={60}
        height={60}
        className="logimg"
      />
      </Link>

        <Link
          to="/"
          className="href-bg"
        >
          {siteTitle}
        </Link>

      </div>
      <nav>
        <ul className="nv-l">
          <li>
          <Link
            to="/"
            className="href-bg"
          >
            Úvod
          </Link>
          </li>
          <li>
          <Link
            to="/blog"
            className="href-bg"
          >
            Blog
          </Link>
          </li>
          <li>
            <Link
              to="/studijni-materialy"
              className="href-bg"
            >
              Studijní materiály
            </Link>
            <ul className="submenu">
              <li>
                <Link to="/biogeografie"
                  className="href-bg"
                >
                  Biogeografie
                </Link>
              </li>
              <li>
                <Link
                  to="/ekologie-zivotni-prostredi"
                  className="href-bg"
                >
                  Ekologie a životní prostředí
                </Link>
              </li>
              <li>
                <Link
                  to="/gis"
                  className="href-bg"
                >
                  GIS a kartografie
                </Link>
              </li>
              <li>
                <Link
                  to="/hydrologie"
                  className="href-bg"
                >
                  Hydrologie
                </Link>
              </li>
              <li>
                <Link
                  to="/zaklady-geodezie-gps"
                  className="href-bg"
                >
                  Základy geodézie a GPS
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
