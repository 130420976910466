import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = () => (

  <footer>
  <div className="f_h1">
  <p className="footer-text">
    Projekt spravuje a tvoří <a className="href-bg footer-bold" target="_blank" rel="nofollow noopener" href="https://www.linkedin.com/in/vojtechovskych/">Míša Vojtěchovská</a>, ©2019-{new Date().getFullYear()}
  </p>
  </div>
  </footer> )

export default Footer
